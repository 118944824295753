import React from 'react';
import './bootstrap.css';
import './styles.css';

function About() {
  return (
<div className="page"> 
      <section className="bg-default" styleName="padding:20px;">
          <div className="container"> 
                <nav className="navbar">
                  <ul className="nav wrap">
                  <li><a className="active" href="/">Home</a></li>
                  <li><a href="/clients">Clients</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                 </ul>
                </nav>
              </div>
        <div class="clear"></div>
        <div className="container">
          <h3>Caretaker Designs</h3>
          <div className="row row-50">
            <div className="col-md-6 col-xl-12">
              <article className="post-minimal">
                <div className="post-classic-body">
                  <p>Caretaker Designs is a freelance Multimedia and Website Design firm currently in the market for new projects. We are skilled in designing websites to meet many needs. Pixel perfect images, interactivity and contact with your clients. We also update and optimize current websites. The final product will function seamlessly on all devices and browsers. Contact us today.</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>      

      <footer className="footer-corporate">
        <div className="container">
          <div className="footer-corporate__inner">
            <p className="rights"><span>Caretaker Designs</span><span>&nbsp;&copy;&nbsp;</span>2020
            </p>
          </div>
        </div>
      </footer>
    <div className="snackbars" id="form-output-global"></div>
    <script src="./js/core.min.js"></script>
    <script src="./js/script.js"></script>
    </div>
  );
}
    
export default About;