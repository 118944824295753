import React from 'react';
import './bootstrap.css';
import './styles.css';

function Contact() {
  return (
    <div className="page">
      <section className="bg-default" styleName="padding:20px;">
      <div className="container">
                <nav className="navbar">
                  <ul className="nav wrap">
                  <li><a href="/">Home</a></li>
                  <li><a href="/clients">Clients</a></li>
                  <li><a className="active" href="/contact">Contact</a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                 </ul>
                </nav>
              </div>
       <div class="clear"></div>
      </section>
      <section className="breadcrumbs-custom">
        <div className="container">
          <div className="breadcrumbs-custom__inner">
            <p className="breadcrumbs-custom__title">Contact</p>
          </div>
        </div>
      </section>

      <section className="bg-default text-center" styleName="padding:20px;">
        <div className="container">
          <h3><a href="mailto:mail.brettoconnor@gmail.com">Contact Caretaker Designs</a></h3>
          </div>
          </section>

      <footer className="footer-corporate">
        <div className="container">
          <div className="footer-corporate__inner">
            <p className="rights"><span>Caretaker Designs</span><span>&nbsp;&copy;&nbsp;</span>2020
            </p>
          </div>
        </div>
      </footer>
    <div className="snackbars" id="form-output-global"></div>
    <script src="./js/core.min.js"></script>
    <script src="./js/script.js"></script>
    </div>
  );
}

export default Contact;
