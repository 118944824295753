import React from 'react';
import './bootstrap.css';
import './styles.css';

function Privacypolicy() {
  return (
    <div className="page"> 
      <section className="bg-default" styleName="padding:20px;">
      <div className="container"> 
                <nav className="navbar">
                  <ul className="nav wrap">
                  <li><a href="/">Home</a></li>
                  <li><a href="/clients">Clients</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a className="active" href="/privacypolicy">Privacy Policy</a></li>
                 </ul>
                </nav>
              </div>
       <div class="clear"></div>
      </section>
      <section className="breadcrumbs-custom">
        <div className="container">
          <div className="breadcrumbs-custom__inner">
            <p className="breadcrumbs-custom__title">Privacy Policy</p>
          </div>
        </div>
      </section>

      <section className="bg-default" styleName="padding:20px;">
        <div className="container">
          <div className="row row-50">
            <div className="col-md-6 col-xl-12">
              <article className="post-minimal">
                <div className="post-classic-body">
                  <p>My website may contain links to other websites. I am not responsible for the privacy practices of any such other website (whether accessed through an advertisement, service, or content link) and urge you to review such practices prior to submitting any personal information to such websites.</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>      

      <footer className="footer-corporate">
        <div className="container">
          <div className="footer-corporate__inner">
            <p className="rights"><span>Caretaker Designs</span><span>&nbsp;&copy;&nbsp;</span>2020
            </p>
          </div>
        </div>
      </footer>
    <div className="snackbars" id="form-output-global"></div>
    <script src="./js/core.min.js"></script>
    <script src="./js/script.js"></script>
    </div>
  );
}

export default Privacypolicy;
