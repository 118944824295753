import React from 'react';
import './bootstrap.css';
import './styles.css';

function Clients() {
  return (
    <div className="page">
      <section className="bg-default" styleName="padding:20px;">
      <div className="container">
                <nav className="navbar">
                  <ul className="nav wrap">
                  <li><a href="/">Home</a></li>
                  <li><a className="active" href="/clients">Clients</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                 </ul>
                </nav>
              </div>
       <div class="clear"></div>
      </section>
      <section className="breadcrumbs-custom">
        <div className="container">
          <div className="breadcrumbs-custom__inner">
            <p className="breadcrumbs-custom__title">Clients</p>
          </div>
        </div>
      </section>

      <section className="bg-default text-center" styleName="padding:20px;">
        <div className="container">
          <div className="row row-50">
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><a href="http://seanoconnor.caretakerdesigns.com/"><img src="images/image1.jpg" alt="" width="418" height="315"/></a>
                <div className="post-classic-title">
                  <h5>Sean O'Connor</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><a href="http://cargocollective.com/oconnor"><img src="images/image3.jpg" alt="" width="418" height="315"/></a>
                <div className="post-classic-title">
                  <h5>Sean O'Connor - PetDesk</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image2.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>Dr. Brian O'Connor MD</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <br/>

     <section className="bg-default text-center" styleName="padding:20px;">
        <div className="container">
          <div className="row row-50">
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image4.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>Stage 1901</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image5.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>BRP Landing Page</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image6.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>BRP Landing Page</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <br/>

      <section className="bg-default text-center" styleName="padding:20px;">
        <div className="container">
          <div className="row row-50">
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image7.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>Big Logo Advertising</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image8.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>Sean O'Connor</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-4">
              <article className="post-minimal"><img src="images/image9.jpg" alt="" width="418" height="315"/>
                <div className="post-classic-title">
                  <h5>William Caton MD</h5>
                </div>
                <div className="post-meta">
                  <div className="group-xs">
		      <span>Client</span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-corporate">
        <div className="container">
          <div className="footer-corporate__inner">
            <p className="rights"><span>Caretaker Designs</span><span>&nbsp;&copy;&nbsp;</span>2020
            </p>
          </div>
        </div>
      </footer>
      <div className="snackbars" id="form-output-global"></div>
    <script src="./js/core.min.js"></script>
    <script src="./js/script.js"></script>
    </div>
  );
}

export default Clients;
