import React, { Component } from 'react';
import About from "./about";
import Clients from "./clients";
import Contact from "./contact";
import Privacypolicy from "./privacypolicy";
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Route exact path="/" component={About} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/privacypolicy" component={Privacypolicy} />
                </div>
            </Router>
        );
    }
}

export default App;